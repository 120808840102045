<template>
    <q-page class="flex flex-center text-center">
        <div class="q-pa-md">
            <q-table
                title="Students"
                :rows="rows"
                :columns="columns"
                row-key="id"
                v-model:pagination="pagination"
                :loading="loading"
                :filter="filter"
                @request="onRequest"
                binary-state-sort
            >
                <template v-slot:top-right>
                    <!-- Fixed size, need improvement to be more responsive -->
                    <q-select style="width: 200px;" outlined v-model="filter_option_selected" :options="filter_options" label="Column" />
                    <q-input borderless dense debounce="300" class="q-ml-md" v-model="filter" placeholder="Search">
                    <template v-slot:append>
                        <q-icon name="search" />
                    </template>
                    </q-input>
                </template>
                <template v-slot:body-cell-details="props">
                    <q-td :props="props">
                        <q-btn icon="link" @click="onStudentDetails(props.row)"></q-btn>
                    </q-td>
                </template>
            </q-table>
        </div>
    </q-page>
</template>

<style>
</style>

<script>
import { useRouter } from 'vue-router'
import { ref, watch } from 'vue'
import { useQuery, useResult } from '@vue/apollo-composable'
import { useQuasar } from 'quasar'

export default {
    name: 'StudentList',

    data () {
        return {
            columns: [
                { name: 'first_name', align: 'left', label: 'Firstname', field: 'first_name', sortable: true },
                { name: 'last_name', align: 'left', label: 'Lastname', field: 'last_name', sortable: true },
                { name: 'email', align: 'left', label: 'Email', field: 'email', sortable: false },
                { name: 'student_number', align: 'left', label: 'Matricule', field: 'student_number', sortable: false },
                {
                    name: 'id',
                    required: true,
                    label: 'ID',
                    align: 'left',
                    field: 'id',
                    sortable: true
                },
                { name: 'details', align: 'left', label: 'Détails', sortable: false },
            ],
            // Filter id don't work because type UUID
            filter_options: [/*'id', */'first_name', 'last_name', 'email', 'matricule'],
            filter_option_selected: ref('matricule'),
            //rows: [],
            filter: '',
        }
    },

    methods: {
        onRequest (query) {
            this.pagination = query.pagination
            if (query.filter !== "") {
                if (this.filter_option_selected === 'email') {
                    this.pagination.filters = {
                        "emails": {
                            "email": {
                                "_ilike": `%${query.filter}%`
                            }
                        }
                    }
                } else if (this.filter_option_selected === 'matricule') {
                    this.pagination.filters = {
                        "student_informations": {
                            "student_number": {
                                "_ilike": `%${query.filter}%`
                            }
                        }
                    }
                } else {
                    this.pagination.filters = {
                        [this.filter_option_selected]: {
                            "_ilike": `%${query.filter}%`
                        }
                    }
                }
            } else {
                this.pagination.filters = {}
            }

            // Add default filter student_informations to only get students
            this.pagination.filters = {
                ...this.pagination.filters,
                "student_informations": {}
            }


            this.variables = {
                startRow: this.pagination.page * this.pagination.rowsPerPage - this.pagination.rowsPerPage,
                count: this.pagination.rowsPerPage === 0 ? this.pagination.rowsNumber : this.pagination.rowsPerPage,
                orderBy: {
                    [this.pagination.sortBy]: this.pagination.descending ? 'desc' : 'asc'
                },
                filters: this.pagination.filters
            }

            this.variablesCount = {
                filters: this.pagination.filters
            }
        },
        onStudentDetails(row) {
            this.router.push({
                name: 'StudentDetails',
                params: {
                    id: row.id
                }
            })
        }
    },
    setup () {
        // Router
        const router = useRouter()
        const quasar = useQuasar()

        // Default variables
        let pagination = ref({
            sortBy: 'last_name',
            descending: false,
            page: 1,
            rowsPerPage: 10,
            rowsNumber: 10,
            filters: {student_informations: {}}
        })

        // GetStudentsCount
        const variablesCount = ref({
            filters: pagination.value.filters
        })
        const { result: resultCount } = useQuery(require('@/gql/students/GetStudentsCount.gql'), variablesCount)

        const rowsNumber = useResult(resultCount, 10, data => data.persons_aggregate.aggregate.count)
        watch(rowsNumber, () => {
            pagination.value.rowsNumber = rowsNumber.value
        })

        // GetStudents
        // Declare variables ref outside useQuery to pass variables in the first request
        const variables = ref({
            startRow: (pagination.value.page - 1) * pagination.value.rowsPerPage,
            count: pagination.value.rowsPerPage === 0 ? pagination.value.rowsNumber : pagination.value.rowsPerPage,
            orderBy: {
                [pagination.value.sortBy]: pagination.value.descending ? 'desc' : 'asc'
            },
            // Filter example:
            // filters: {"first_name": {"_ilike": "Maxence"}}
            filters: pagination.value.filters
        })
        const { result, loading, onError } = useQuery(require('@/gql/students/GetStudents.gql'), variables)

        onError(error => {
            console.log(error)
            quasar.notify({
                    color: 'red',
                    textColor: 'white',
                    icon: 'error',
                    message: error.message,
                    position: 'top',
                    timeout: 5000
            })
        })

        const rows = useResult(result, [], data => {
            return data.persons.map(row => ({
                id: row.id,
                first_name: row.first_name,
                last_name: row.last_name,
                email: row.emails[0]?.email,
                student_number: row.student_informations[0]?.student_number
            }))
        })

        return {
            loading,
            rows,
            pagination,
            variables,
            variablesCount,
            router
        }
    }
}
</script>

<style>
/* Change color when hover line in table */
tr:hover {
    background-color: #DCDCDC;
}
</style>
